import "./assets/css/home.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/all-routes";
import "./assets/css/footer.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 

import "aos/dist/aos.css";
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
