import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrrolOnrender from "../Common-components/scrol-on-render";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    mail: "",
    message: "",
  });

  const emailAddress = "josewin100@gmail.com";
  const subject = "New message from your website GJinteriors";

  useEffect(() => {
    if (submitted && name !== "" && phone !== "" && message !== "") {
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject
      )}&body=Name: ${name}%0D%0APhone: ${phone}%0D%0AMessage: ${message}%0D%0AMail: ${mail}`;
      window.location.href = mailtoLink;
      toast.success("Mail sent successfully");

      setSubmitted(false); // Reset the form submission state
      setName("");
      setPhone("");
      setMail("");
      setMessage("");
      setErrors({
        name: "",
        phone: "",
        mail: "",
        message: "",
      });
    }
  }, [submitted, name, phone, mail, message]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    const updatedErrors = { ...errors };

    if (name.trim() === "") {
      updatedErrors.name = "Please enter your name.";
      isValid = false;
    } else {
      updatedErrors.name = "";
    }

    if (phone.trim() === "") {
      updatedErrors.phone = "Please enter your phone number.";
      isValid = false;
    } else {
      updatedErrors.phone = "";
    }
    if (mail.trim() === "") {
      updatedErrors.mail = "Please enter your mail.";
      isValid = false;
    } else {
      updatedErrors.mail = "";
    }

    if (message.trim() === "") {
      updatedErrors.message = "Please enter your message.";
      isValid = false;
    } else {
      updatedErrors.message = "";
    }
    if (!isValid) {
      setErrors(updatedErrors);
      return;
    }
    setSubmitted(true); // Triggers the useEffect to send the email
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Connect with GJ Interiors: Your Dream Home Awaits.</title>
          <link rel="canonical" href="http://gjinterior.com/contact" />
          <meta
            name="description"
            content="Looking to get in touch with gjinteriors? Visit our contact page to reach out to our team for all your interior design needs. Contact gjinteriors today! "
          />
        </Helmet>
      </div>
      <ScrrolOnrender />
      <ToastContainer id="myContainer" />
      <section>
        <div className="bg-contactbanner font-popins h-[300px]  bg-no-repeat bg-bottom bg-cover relative  ">
          <div className=" w-full h-full bg-black opacity-[0.7] "></div>
          <div className="absolute top-0 z-10 flex flex-col items-start justify-center w-full h-full p-10 text-white ">
            <div>
              <nav aria-label="breadcrumb" className="w-full p-4 text-white">
                <ol className="flex h-8 space-x-2">
                  <li className="flex items-center">
                    <Link
                      rel="noopener noreferrer"
                      title="Back to homepage"
                      className="hover:underline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 pr-1 text-white"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                    </Link>
                  </li>
                  <li className="flex items-center space-x-1">
                    <span className="text-white">/</span>
                    <Link
                      rel="noopener noreferrer"
                      className="flex items-center px-1 capitalize hover:underline"
                    >
                      Welcom to Contact Page
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="text-[60px] font-medium">Contact Page</div>
          </div>
        </div>
      </section>
      {/* ss */}
      <section class="contact-page-sec md:px-16 py-10  max-md:px-3">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2 font-popins">
          <div class="">
            <div class="contact-info ">
              <div class="contact-info-item p-8 md:h-[250px]">
                <div class="contact-info-icon">
                  <i class="fas fa-map-marked icon"></i>
                </div>
                <div class="contact-info-text">
                  <h2>address</h2>
                  <span>6/6-5,MATHARAMPUTHOOR,KOTTARAM, POTRAIYADI POST,</span>
                  <span>kanyakumari,Tamilnadu,629703</span>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="contact-info">
              <div class="contact-info-item p-8 md:h-[250px]">
                <div class="contact-info-icon">
                  <i className="fas fa-envelope icon"></i>
                </div>
                <div class="contact-info-text">
                  <h2>E-mail</h2>
                  <span>josewin100@gmail.com</span>
                  <span className="mt-5">9486412933</span>
                  {/* <span>yourmail@gmail.com</span> */}
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="contact-info">
              <div class="contact-info-item p-8 md:h-[250px]">
                <div class="contact-info-icon">
                  <i class="fas fa-clock icon"></i>
                </div>
                <div class="contact-info-text">
                  <h2>office time</h2>
                  <span>Mon - Thu 9:00 am - 5.00 pm</span>
                  <span>sat - Mon 9.00 pm - 5.00 pm</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-2 bg-gray-200 rounded-lg mt-5 ">
          <div class="">
         
            <div class="contact-page-form" method="post">
            <h2 className="w-full text-xl font-medium text-center">Get in Touch</h2>
              <form action="contact-mail.php" method="post">
                
                <div class="row">
                  <div class="">
                    
                    <div class="single-input-field">
                      {errors.name && (
                        <span className="text-red-500 error">
                          {errors.name}
                        </span>
                      )}
                      <input
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="">
                    <div class="single-input-field">
                      {errors.mail && (
                        <span className="text-red-500 error">
                          {errors.mail}
                        </span>
                      )}
                      <input
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        required
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="">
                    <div class="single-input-field">
                      {errors.phone && (
                        <span className="text-red-500 error">
                          {errors.phone}
                        </span>
                      )}
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="">
                    <div class="single-input-field">
                      {errors.message && (
                        <span className="text-red-500 error">
                          {errors.message}
                        </span>
                      )}
                      <textarea
                        placeholder="Write Your Message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div class="single-input-fieldsbtn">
                    <input
                      onClick={handleFormSubmit}
                      type="submit"
                      value="Send Now"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="">
            <div class="contact-page-map flex items-center justify-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.585469089049!2d77.5722656!3d8.143612500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b048d01c87e302b%3A0x486366eadd6ca4de!2sG%20J%20PVCDOOR%26ALUMINUM%20FABRICATION!5e0!3m2!1sen!2sin!4v1714839951303!5m2!1sen!2sin"
                width="600"
                height="450"
                title="Google Map - G J PVCDOOR & ALUMINUM FABRICATION"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section></section>
    </>
  );
};

export default Contact;
