import React from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/images/section-bg/news.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaRegEnvelopeOpen } from "react-icons/fa6";

import { FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer class="footer style-1 ">
        <div class="overlay"></div>
        <div class="footer-sec">
          <div class="container mx-auto sm:px-10">
            <section className="py-6 ">
              <div className="container flex flex-col justify-center p-4 mx-auto">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                  <div class="footer-widget">
                    <div class="footer-widget-info">
                      <div class="footer-logo">
                        <a href="index.html">
                          <img src={Logo} alt="Footer Logo" />
                        </a>
                      </div>
                      <p cl>
                        Interior businesses curate spaces with creativity,
                        merging aesthetics and functionality to craft inviting
                        environments that reflect individual styles and
                        preferences.
                      </p>
                      <div class="social-profile">
                        <Link>
                          <FaFacebookF />
                        </Link>
                        <Link
                          data-aos="fade-down"
                          data-aos-easing="linear"
                          data-aos-duration="1500"
                        >
                          <FaTwitter />
                        </Link>
                        <Link
                          data-aos="fade-down"
                          data-aos-easing="linear"
                          data-aos-duration="1500"
                        >
                          <FaInstagram />
                        </Link>
                        <Link>
                          <FaPinterestP />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* ================ */}

                  {/* ================= */}
                  <div class="footer-widget font-popins">
                    <h2 class="footer-widget-title">Contact</h2>
                    <div class="">
                      <div class="footer-widget-contact">
                        <ul>
                          <li className="mb-4 text-xl font-medium">
                            Head Office
                          </li>
                          <li>
                            <p className="text-10px]">
                              6/6-5,MANTHARAMPUTHOOR,KOTTARAM,
                              <br /> POTRAIYADI
                              POST,kanyakumari,Tamilnadu,629703{" "}
                            </p>
                          </li>
                        </ul>

                        <div class="footer-contact">
                          <ul>
                            <li>
                              <div class="contact-icon">
                                <FaRegEnvelopeOpen />
                              </div>
                              <div class="contact-text">
                                <a href="mailto:hello@technova.com">
                                  <span>josewin100@gmail.com</span>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="contact-icon">
                                <FaPhoneAlt />
                              </div>
                              <div class="contact-text">
                                <a href="tel:+919486412933">9486412933</a>
                              </div>
                            </li>
                            <li>
                              <div class="contact-icon">
                                <FaPhoneAlt />
                              </div>
                              <div class="contact-text">
                                <a href="tel:+918778868580">87788 68580</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* =========== */}
                  <div class="footer-widget widget_nav_menu font-popins">
                    <h2 class="footer-widget-title">Our Branchs</h2>
                    <ul>
                      <li className="">
                        10/4B-7,KANIMADAM,ANJUGRAMAM POST, kanyakumari,Tamil
                        Nadu,629703
                      </li>
                      <li className="text-xl font-semibold">Branch three</li>
                      <li>
                        No. 110E/20/3,Bye-Pass Road North,Barani Nagar
                        Vannarpetti, Thirunelveli-627003.
                      </li>
                    </ul>
                  </div>
                  {/*==== */}
                  <div class="footer-widget">
                    <h2 class="footer-widget-title">Best Place !</h2>

                    <div class="">
                      <div class="contact-page-map flex items-center justify-center">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.585469089049!2d77.5722656!3d8.143612500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b048d01c87e302b%3A0x486366eadd6ca4de!2sG%20J%20PVCDOOR%26ALUMINUM%20FABRICATION!5e0!3m2!1sen!2sin!4v1714839951303!5m2!1sen!2sin"
                          width="600"
                          height="250"
                          title="Google Map - G J PVCDOOR & ALUMINUM FABRICATION"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <hr />
          </div>
        </div>
        <div class="footer-bottom-area">
          <div class="container mx-auto px-10">
            <div class="">
              <div class="">
                <div class="footer-bottom-wrapper">
                  <div class="footer-bottom-menu-wrapper">
                    <div class="copyright-text">
                      <p>© gjinteriors | All Rights Reserved</p>
                      <p className="p-2 font-medium text-white rounded-lg ">
                       Website developed by: Sharmila Sivakumar  / <a href="tel:+919095161931">90951 61931</a> / for service 
                        
                      </p>
                    </div>
                    <div class="footer-bottom-menu">
                      <ul>
                        <li>
                          <Link>Terms &amp; Condition</Link>
                        </li>
                        <li>
                          <Link>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link>Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
