import {
    createBrowserRouter,
  } from "react-router-dom";
  
//   import Root, { rootLoader } from "./routes/root";
//   import Team, { teamLoader } from "./routes/team";
import Layoutpage from "../pages/Layoutpage";
import Home from "../pages/Home";
import Aboutus from "../pages/Aboutus";
import Services from "../pages/Services";
import Contact from "../pages/contact-us";

 export const router = createBrowserRouter([
   {
     path: "/",
     element: <Layoutpage />,
     children: [
       {
         index: true,
         element: <Home />,
       },
       {
         path: "/about",
         element: <Aboutus />,
       },
       {
         path: "/services",
         element: <Services />,
       },
       {
         path: "/contact-us",
         element: <Contact />,
       },
     ],
   },
 ]);



// **************************** //
// Private Route
// **************************** //

// {
//     path: '/dashboard',
//     element: (
//       <PrivateRoute>
//         <DashboardPage />
//       </PrivateRoute>
//     ),
//   },

// function PrivateRoute({ children }) {
//   const isAuthenticated = checkUserAuthentication();
//   return isAuthenticated ? children : <Navigate to="/login" />;
// }

