import React from "react";
import { Link } from "react-router-dom";
import "../assets/about.scss";
import AboutimageOne from "../assets/images/aboutusimages/about-sectionimage/image-one.jpg";
import AboutimageTwo from "../assets/images/aboutusimages/about-sectionimage/image-two.jpeg";
import AboutimageThree from "../assets/images/aboutusimages/about-sectionimage/sofa.png";

import ScrrolOnrender from "../Common-components/scrol-on-render";
import { Helmet } from "react-helmet";
const Aboutus = () => {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Behind GJ Interiors: The Story Unfolded</title>
          <link rel="canonical" href="http://gjinterior.com/about" />
          <meta
            name="description"
            content="Discover what gjinteriors is all about on our About page. Learn how our team of experts can transform your space with our unique designs and personalized approach. Join us and let's create the home of your dreams together. "
          />
        </Helmet>
      </div>
      <ScrrolOnrender />
      <section>
        <div className="bg-aboutbannerimage font-popins h-[300px] bg-fixed bg-no-repeat bg-bottom bg-cover relative  ">
          <div className=" w-full h-full bg-black opacity-[0.7] "></div>
          <div className="absolute top-0 z-10 flex flex-col items-start justify-center w-full h-full p-10 text-white ">
            <div>
              <nav aria-label="breadcrumb" className="w-full p-4 text-white">
                <ol className="flex h-8 space-x-2">
                  <li className="flex items-center">
                    <Link
                      rel="noopener noreferrer"
                      title="Back to homepage"
                      className="hover:underline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 pr-1 text-white"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                    </Link>
                  </li>
                  <li className="flex items-center space-x-1">
                    <span className="text-white">/</span>
                    <Link
                      rel="noopener noreferrer"
                      className="flex items-center px-1 capitalize hover:underline"
                    >
                      Return to About Us Page
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="text-[60px] font-medium"> About Us</div>
          </div>
        </div>
      </section>
      {/* about us section */}
      <section className="container px-5 mx-auto my-20 md:px-20 font-popins">
        {/* grid  */}
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 sm:grid-cols-1">
          <div className="relative w-full h-[500px] ">
            <img
              src={AboutimageOne}
              className="w-[70%] rounded-md h-[26rem] object-cover"
              alt="loading"
            />
            <img
              src={AboutimageTwo}
              className="absolute right-0 z-10 p-5 bg-white rounded-md shadow-sm roundobject-contain -bottom-20 h-96"
              alt="loading"
            />
            <div className="absolute w-auto  h-auto px-5  py-3 z-20 rounded-md text-white bg-[#b8873e] -bottom-10 ">
              {/* flex */}
              <div className="flex items-center gap-5">
                <div>
                  <img
                    src={AboutimageThree}
                    className="h-20 bg-white rounded-md shadow-sm "
                    alt="loading"
                  />
                </div>
                <div>
                  {" "}
                  <div className="text-[30px] font-semibold flex justify-between items-center">
                    <span>5k</span>{" "}
                  </div>
                  <div className="text-[15px]">Projects Completed</div>
                </div>
              </div>
            </div>
          </div>
          <div className=" max-lg:mt-20 md:pl-14">
            <h6 className="text-[#b8873e] font-bold ">ABOUT US --</h6>
            <h1 className="text-[38px] font-medium my-[5%]">
              Enhance Your Lifestyle with Our Exceptional Interior Design
              Services
            </h1>
            {/* <p>
              It is a long established fact that a reader will be distracted by
              the readablejk content of a page when looking at its layout. Lorem
              Ipsum is simply dummyj
            </p> */}
            <p className="my-[5%]">
              {" "}
              With 26 years of experience,  <span className="font-serif text-xl font-medium text-blue-800">GJ Interiors</span>, a renowned firm based
              in India, has been dedicated to crafting high-quality interior
              designs for both commercial and residential spaces. GJ Interiors
              serves as your comprehensive solution for home and workplace
              interior design and decoration. You dream it, and we'll bring it
              to life.
            </p>
            <p>
              With a deep understanding of your design preferences, we involve
              you in our design process to ensure that your envisioned living
              space becomes a reality. Our innovative designs and unique ideas
              will help you achieve the desired look for your space.
            </p>

            <Link
              to={"/contact-us"}
              class="btn-flip mt-16 rounded-md text-white"
              data-back="Let's Move "
              data-front="Contact Us For More"
            ></Link>
          </div>
        </div>
      </section>
      {/* ==== */}
      <section className="container px-20 mx-auto font-popins max-md:px-5">
        <div className="p-5 rounded-md shadow-lg shadow-gray-500 ">
          <h1 className="text-2xl font-semibold text-[#b8873e] border-[#b8873e] w-fit border-b-2 pb-2 mb-2">
            No. one Interior Designe Service{" "}
          </h1>
          <p className="font-medium leading-loose text-justify text-md">
            {" "}
            Designs Master Bedroom | Guest Bedroom | Kids Bedroom | Study Room |
            Parents Room | Small Bedroom | U-shape Modular Kitchen | L-shape
            Modular Kitchen | Parallel Modular Kitchen | Island Kitchen |
            Straight Kitchen | Modular Kitchen | Living Room | Dining Room |
            Foyer Area | Sliding Door Wardrobe | Swing Door Wardrobe | Walkin
            Wardobe
          </p>
        </div>
      </section>
      {/* ========== */}

      <section className="container mt-10 md:px-20 max-auto">
        <div className="bg-[#b8873e] lg:flex">
          <div className="w-full bg-cover lg:w-1/3 bg-aboutimageone"></div>
          <div className="flex flex-col w-full p-6 text-white lg:w-2/3 md:p-8 lg:p-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 mb-8 "
            >
              <path
                fillRule="evenodd"
                d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h2 className="text-3xl font-semibold leading-none">
              Modern Solutions Tailored for Every Interior Challenge
            </h2>
            <p className="mt-4 mb-8 text-sm">
              "Experience innovative interior solutions tailored to your needs
              and preferences, ensuring every space reflects modern elegance and
              functionality."
            </p>

            <Link to={"/contact-us"}>
              {" "}
              <button className="self-start px-10 py-3 text-lg font-medium text-black bg-white rounded-3xl">
                {" "}
                Get started{" "}
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* --- */}

      {/* --- */}
      <section className="container px-5 mx-auto my-20 text-black md:px-16 font-popins">
        <div className="flex flex-col justify-center py-8 mx-auto md:p-8">
          <h2 className="text-2xl font-semibold sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 mb-8 dark:text-gray-600">
            <span className="text-xl dont-bold "> Transform Your Space</span> -
            Wondering how you can transform your space? We've got you covered.
          </p>
          <div className="space-y-4 max-sm:text-[12px]">
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:dark:ring-violet-600">
                How can I transform my space to reflect my style and
                personality?
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-600">
                Transforming your space to reflect your style and personality
                can be achieved through various methods such as choosing
                furniture and decor that resonate with you, incorporating
                personal touches like family photos or artwork, and
                experimenting with different colors and textures to create a
                cohesive look that speaks to who you are.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:dark:ring-violet-600">
                What are some tips for elevating my interior design to the next
                level?
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-600">
                Elevating your interior design can be accomplished by paying
                attention to details such as lighting, texture, and layout.
                Consider investing in statement pieces, layering textures for
                visual interest, and ensuring proper lighting to enhance the
                ambiance of your space.
              </p>
            </details>
            <details className="w-full border rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:dark:ring-violet-600">
                Where can I find inspiration for modern interior designs?
              </summary>
              <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-600">
                You can find inspiration for modern interior designs from
                various sources such as design magazines, websites, social media
                platforms like Pinterest and Instagram, as well as by visiting
                home decor stores and attending design events or exhibitions.
              </p>
            </details>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
