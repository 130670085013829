import React from "react";

const Mainservice = (props) => {
  return (
    <>
      <section>
        <div className="relative w-auto h-auto Baner-black-white ">
          <img
            className="object-cover w-full h-[200px] rounded-3xl max-md:h-[200px] max-sm:h-[150px] "
            src={props.img}
            alt="loading"
          />
          {/* <div className="absolute top-0 z-10 w-full h-full bg-gray-800 opacity-[0.3] rounded-3xl"></div> */}

          <h2 className="  font-medium text-[25px] max-md:text-[20px] max-sm:text-[13px] mt-5 text-[#d9a14c] ml-2 font-popins">
            {props.heading}
          </h2>
       
        </div>
      </section>
    </>
  );
};

export default Mainservice;
