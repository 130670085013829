import React, { useEffect } from "react";
import CountUp, { useCountUp } from "react-countup";
import AOS from "aos";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import BeforFooterImage from "../assets/images/lastBeforeImage.jpg";
import Slider from "react-slick";
import Logo from "../assets/images/section-bg/news.png";
import Aboutusimage from "../assets/images/section-bg/about-usimage.jpg";
import ProcessImageTwo from "../assets/images/section-bg/commercials-interior-designers-in-chennai-peril.png";
import ProcessImageThree from "../assets/images/section-bg/home-interiors-in-chennao-peril.png";
import ProcessImageone from "../assets/images/section-bg/office-interiors-in-chennai-peril.png";
import { Link } from "react-router-dom";
import ScrrolOnrender from "../Common-components/scrol-on-render";
import { Helmet } from "react-helmet";
import Marquee from "react-fast-marquee";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiLogoGmail, BiSolidQuoteLeft } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import Banner from "../Common-components/new";

import Mainservice from "../Common-components/service-components/mainservice";
import MainImageone from "../assets/images/service-images/Acp-images.jpg";
import MainImageTwo from "../assets/images/service-images/toughen-glass.jpg";
import MainImageThree from "../assets/images/service-images/mosqutio.jpg";
import MainImageFour from "../assets/images/service-images/ceiling.webp";
import MainImageFive from "../assets/images/service-images/welding.jpg";
import MainImageSix from "../assets/images/service-images/tenzhing.webp";
const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  useCountUp({
    ref: "counter",

    enableScrollSpy: true,
    scrollSpyDelay: 1000,
  });

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-40  flex h-auto pt-1 top-[13rem] max-md:top-5 left-7 max-sm:left-0">
        <button onClick={onClick}>
          <h2 className="">
            <MdArrowBackIosNew className="text-black text-[30px]" />
          </h2>
        </button>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="absolute  max-md:top-5 flex h-auto pt-1 top-[14rem]   max-md:left-10 right-7"
        style={{ ...style }}
      >
        <button onClick={onClick}>
          <h2 className="">
            <MdArrowForwardIos className="text-black text-[30px]" />
          </h2>
        </button>
      </div>
    );
  }

  return (
    <>
      <Banner />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Transform Your Home with GJ Interiors Expertise</title>
          <link rel="canonical" href="http://gjinterior.com/home" />
          <meta
            name="description"
            content="Looking to elevate your home decor? Explore the stunning gjinteriors home page for inspiration, tips, and unique design ideas. Refresh your space with gjinteriors' expert touch. "
          />
        </Helmet>
      </div>

      <ScrrolOnrender />

      <section className="container mx-auto  lg:px-10 max-lg:max-w-[95%] lg:max-w-[99%] overflow-auto">
        <section class="sm:container sm:mx-auto  sm:px-4 ">
          <div class="section-about ">
            <section className="py-6 ">
              <div className="container flex flex-col justify-center p-10">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
                  <div class="">
                    <strong
                      class="section-subtitle"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    >
                      about us
                    </strong>
                    <h2
                      class="section-title section-about-title about-section-home-htwo"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    >
                      "We lead the way in architectural innovation and design.
                    </h2>
                    <p data-aos="fade-right" data-aos-duration="1200">
                      At{" "}
                      <span className="font-serif text-xl font-medium text-blue-800">
                        {" "}
                        GJ Interiors,
                      </span>{" "}
                      we prioritize building strong partnerships to enhance the
                      value of each project. By fostering collaborations across
                      public and private sectors and facilitating cross-sectoral
                      connections, we enrich our knowledge base and foster
                      mutual learning to deliver exceptional results
                    </p>
                    <div class="experience-box">
                      <div class="experience-border"></div>
                      <div
                        class="experience-content"
                        data-aos="fade-right"
                        data-aos-duration="1200"
                      >
                        <div class="experience-number">26</div>
                        <div class="experience-info wow fadeInDown">
                          Years
                          <br />
                          Experience
                          <br />
                          Working
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ================ */}
                  <div class="dots-image  flex  justify-center ">
                    <img
                      alt="loading"
                      class="about-img img-responsive"
                      src={Aboutusimage}
                      data-aos="fade-left"
                      data-aos-duration="1200"
                    />
                    <div class="dots"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* ================== Marqee ========================== */}
        <section className="tc-testimonials-style1">
          <Marquee className="font-mono font-light">
            <div className="marq-slider swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Link>Creative</Link>
                </div>
                <div className="swiper-slide">
                  <Link>Flexible</Link>
                </div>
                <div className="swiper-slide">
                  <Link>Dedicated</Link>
                </div>
              </div>
            </div>
          </Marquee>
        </section>
      </section>

      {/* ==================================== Main Service  */}

      <section className="relative bg-fixed w-50 bg-bgimagehome">
        <div className="absolute w-full h-full bg-black opacity-[0.9]"></div>

        <div className="container z-30 w-full h-full py-10 mx-auto">
          <section>
            <div className="px-16 mt-16 max-md:px-6">
              <section className="py-6">
                <div className="container flex flex-col justify-center p-4 mx-auto">
                  <div className="z-20 flex flex-col w-full h-full justify-evenly md:hidden">
                    <Link to={"/"}>
                      <img
                        src={Logo}
                        className="object-contain w-full h-full "
                        alt=""
                      />
                    </Link>
                    <p className=" text-center font-normal font-popins text-white max-md:text-[13px] max-md:mt-10">
                      Transform your space with expert interior design services
                      tailored to your style and needs. From consultation to
                      implementation, we'll create a space you'll love to live
                      or work in.
                    </p>
                  </div>
                  <div className="grid max-sm:gap-5 sm:gap-16 max-lg:grid-col-2 max-md:grid-cols-1 max-md:mt-5 max-lg:grid-cols-2 lg:grid-cols-3">
                    <div className="z-20 flex flex-col w-full h-full justify-evenly max-md:hidden ">
                      <Link to={"/"}>
                        <img
                          src={Logo}
                          className="object-contain w-full h-full "
                          alt=""
                        />
                      </Link>
                      <p className="font-normal text-center text-white font-popins ">
                        Transform your space with expert interior design
                        services tailored to your style and needs. From
                        consultation to implementation, we'll create a space
                        you'll love to live or work in.
                      </p>
                    </div>
                    <div data-aos="fade-down-right">
                      {" "}
                      <Mainservice
                        img={MainImageone}
                        heading="Acp elevation work"
                      />
                    </div>
                    <div data-aos="fade-down-right">
                      {" "}
                      <Mainservice
                        img={MainImageTwo}
                        heading="toughened glass design"
                      />
                    </div>
                    <div data-aos="fade-down-right">
                      {" "}
                      <Mainservice
                        img={MainImageThree}
                        heading="mosquito net For Windows"
                      />
                    </div>

                    <div data-aos="fade-down-right">
                      {" "}
                      <Mainservice
                        img={MainImageFour}
                        heading="False ceiling designs "
                      />
                    </div>
                    <div data-aos="fade-down-right">
                      <Mainservice
                        img={MainImageFive}
                        heading="Glass welding "
                      />
                    </div>
                    <div data-aos="fade-down-right">
                      {" "}
                      <Mainservice
                        img={MainImageSix}
                        heading="tenzing sheet roof "
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </section>
      {/* ==================================== Service */}

      <section className="container mx-auto ">
        <div
          className="mt-[5%] feature-area style-2"
          data-aos="zoom-in-up"
          data-aos-duration="1200"
        >
          {" "}
          <div class="section-title">
            <h5 class="short-title">Latest Service</h5>
            <h2 class="title">
              Transform your space with expert design,
              <br /> elevating your home to new heights
            </h2>
          </div>{" "}
        </div>

        <div className="flex gap-5 mt-20 justify-evenly px-11 max-md:px-6 max-lg:flex-col">
          <Link
            to={"/services"}
            className="relative Baner-black-white"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div>
              <img
                className="rounded-xl h-[500px] object-cover w-full"
                src={ProcessImageTwo}
                alt="loading"
              />
              <div className="absolute top-0 w-full h-full bg-black rounded-xl opacity-40"></div>
              <div className="absolute bottom-0 px-10 pb-10">
                <h2 className=" text-[40px] font-semibold text-white">
                  Residential Interiors
                </h2>
                <p className="text-white font-cormantThiner">
                  Get the Best Residential Interior Designers in kerala. Check
                  Our Latest Works Here.
                </p>
              </div>
            </div>
          </Link>

          <Link to={"/services"} data-aos="fade-down" data-aos-duration="1200">
            <div className="relative Baner-black-white">
              <img
                className="rounded-xl h-[500px] object-cover w-full"
                src={ProcessImageThree}
                alt="loading"
              />

              <div className="absolute top-0 w-full h-full bg-black opacity-40 rounded-xl"></div>
              <div className="absolute bottom-0 px-10 pb-10">
                <h2 className=" text-[40px] font-semibold text-white">
                  Corporate Interiors
                </h2>
                <p className="text-white font-cormantThiner">
                  Get the Best Residential Interior Designers in kerala. Check
                  Our Latest Works Here.
                </p>
              </div>
            </div>
          </Link>

          <Link to={"/services"} data-aos="fade-left" data-aos-duration="1200">
            <div className="relative Baner-black-white">
              <img
                className="rounded-xl  h-[500px] object-cover w-full"
                src={ProcessImageone}
                alt="loading"
              />
              <div className="absolute top-0 w-full h-full bg-black rounded-xl opacity-40"></div>
              <div className="absolute bottom-0 px-10 pb-10">
                <h2 className="font-cormantThiner text-[40px] font-semibold text-white">
                  Commercial Interiors
                </h2>
                <p className="text-white font-cormantThiner">
                  Get the Best Residential Interior Designers in kerala. Check
                  Our Latest Works Here.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </section>

      {/* ========================= Achivements*/}
      <div className="my-8 sm:px-4 sm:mx-auto sm:container">
        {" "}
        <section className="py-1 font-popins">
          <section>
            <div className="flex gap-5 px-16 py-20 mt-20 font-mono max-sm:px-6 max-lg:flex-col">
              <div className="w-[40%] max-lg:w-full">
                <ul className="flex">
                  <li className="pt-3 pr-3">
                    <div className="h-[2px] w-[70px] bg-[#d9a14c]"></div>
                  </li>
                  <li>
                    <h2 className=" font-cormantThiner">
                      ENHANCE YOU LIVING EXPERIENCE
                    </h2>
                  </li>
                </ul>

                <h2 className="text-[42px] max-sm:text-[25px]  font-serif ">
                  Our Achievements
                </h2>
                <p className="mt-5 text-justify text-black  max-sm:text-[15px] sm:text-[18px] font-serif ">
                  "We're honored to receive the 'Interior Design Excellence
                  Award,' acknowledging our commitment to crafting exceptional
                  spaces and delivering unforgettable interior experiences for
                  our clients. Our consistent positive feedback and client
                  testimonials have earned us the 'Client Choice Award' for
                  providing top-notch interior design services that consistently
                  exceed expectations."
                </p>

                <div className="lg:w-[38%] md:w-[40%] max-md:w-[40%] py-2 bg-[#d9a14c] max-sm:w-[60%]  mt-10 flex justify-evenly">
                  <div className="text-white uppercase ">
                    <Link to={"/Contact-us"}>
                      <h2 className="mt-1 font-serif">Contact us</h2>{" "}
                    </Link>
                  </div>
                  {/* <BsArrowRight className="font-bold text-[35px] text-white " /> */}
                </div>
              </div>

              <div className="w-[60%] pt-24 max-lg:w-full rounded-xl  text-[#d9a14c]">
                <ul className="flex justify-evenly ">
                  <li>
                    <div>
                      <h2 className="text-[50px] max-sm:text-[30px] font-semibold flex">
                        <span>
                          <CountUp end={50} enableScrollSpy />
                        </span>{" "}
                        <span className=" text-[25px] ml-3">+</span>{" "}
                      </h2>
                      <h2 className="font-semibold text-black">
                        Finished Projects
                      </h2>
                    </div>
                  </li>

                  <li className="pt-10 max-sm:hidden">
                    <div className="h-[30px] w-[2px] bg-black"></div>
                  </li>

                  <li>
                    <div>
                      <h2 className="text-[50px] max-sm:text-[30px] font-semibold flex">
                        <span>
                          <CountUp end={20} enableScrollSpy />
                        </span>{" "}
                        <span className="text[#d9a14c] text-[25px] ml-3 ">
                          +
                        </span>{" "}
                      </h2>
                      <h2 className="font-semibold text-black">
                        On Going Projects
                      </h2>
                    </div>
                  </li>

                  <li className="pt-10">
                    <div className="h-[30px] w-[2px] bg-black"></div>
                  </li>

                  <li>
                    <div>
                      <h2 className="text-[50px] max-sm:text-[30px] font-semibold flex">
                        <span>
                          <CountUp end={20} enableScrollSpy />
                        </span>{" "}
                        <span className=" text-[25px] ml-3">+</span>{" "}
                      </h2>
                      <h2 className="font-semibold text-black">Awards</h2>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </section>{" "}
      </div>

      {/* ==================================================================  */}
      <section className="container px-4 mx-auto font-popins">
        <div
          class="cta-area style-2 "
          data-aos="flip-up"
          data-aos-duration="1200"
        >
          <div class="cta-overlay"></div>
          <div class="" data-aos="zoom-in-up" data-aos-duration="1600">
            <div class="cta-wrapper">
              <div class="about-info-content">
                <div class="section-title">
                  <h5 class="short-title">join with us</h5>
                  <h2 class="title text-center">
                    Your Dream Space, Our <br />
                    Expert Touch
                  </h2>
                </div>
              </div>
              <div class="btn-wrapper">
                <Link class="theme-btn">
                  Learn More<i class="fa-solid fa-plus"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="px-10 pb-10 max-sm:px-1 ">
          <div>
            <h2 className="text-center text-[32px] font-cormantThiner uppercase text-red-600 pt-10 ">
              WHAT OUR CLIENT SAYS
            </h2>
          </div>
          <div>
            <Slider className="" {...settings}>
              <center className="px-7 max-sm:px-0ya">
                <ul className="flex w-[80%] max-md:w-full gap-5 shadow-xl pb-10 pt-10 shadow-gray-500 max-sm:shadow-lg max-lg:flex-col mb-10 mt-20 justify-evenly ">
                  <li>
                    <div className="w-auto h-auto">
                      <div className="px-5 pt-28 max-sm:px-0 max-md:pt-20">
                        <p className="font-cormantThiner">21-9-2023</p>
                        <p className="font-semibold font-cormantThiner text-[25px]">
                          Arun
                        </p>

                        <ul className="flex justify-center ">
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="w-[70%] pr-20 max-lg:px-10 max-lg:w-full">
                    <div className="w-[100%]">
                      <p>
                        <BiSolidQuoteLeft className="text-[102px] text-[#c7993d]" />
                      </p>
                      <p className="text-[18px] max-md:text-[20px] text-black font-cormantThiner text-justify">
                        1. " Thanks to{" "}
                        <span className="font-serif text-xl font-medium text-blue-800">
                          {" "}
                          GJ Interiors,
                        </span>
                        , my house became the home of my dreams. Their attention
                        to detail and innovative design ideas are truly
                        remarkable. From the initial consultation to the final
                        execution, the entire process was smooth and
                        stress-free”.
                      </p>
                    </div>
                  </li>
                </ul>
              </center>

              <center className="px-7">
                <ul className="flex w-[80%] max-md:w-full gap-5 shadow-xl pb-10 pt-10 shadow-gray-500 max-sm:shadow-lg max-lg:flex-col mb-10 mt-20 justify-evenly ">
                  <li>
                    <div className="w-auto h-auto">
                      <div className="px-5 pt-28 max-sm:px-0 max-md:pt-20">
                        <p className="font-cormantThiner">10-10-2023</p>
                        <p className="font-semibold font-cormantThiner text-[25px]">
                          Danial
                        </p>

                        <ul className="flex justify-center ">
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="w-[70%] pr-20 max-lg:px-10 max-lg:w-full">
                    <div className="w-[100%]">
                      <p>
                        <BiSolidQuoteLeft className="text-[102px] text-[#c7993d]" />
                      </p>
                      <p className="text-[18px] max-md:text-[20px] text-black font-cormantThiner text-justify">
                        2. " It was a pleasure working with{" "}
                        <span className="font-serif text-xl font-medium text-blue-800">
                          {" "}
                          GJ Interiors,
                        </span>{" "}
                        on my workspace, and I'm really pleased with the
                        outcome. I was astonished by the team's exceptional
                        originality and professionalism. They succeeded in
                        creating the ideal harmony between practicality and
                        beauty."
                      </p>
                    </div>
                  </li>
                </ul>
              </center>

              <center className="px-7">
                <ul className="flex w-[80%] max-md:w-full gap-5 shadow-xl pb-10 pt-10 shadow-gray-500 max-sm:shadow-lg max-lg:flex-col mb-10 mt-20 justify-evenly ">
                  <li>
                    <div className="w-auto h-auto">
                      <div className="px-5 pt-28 max-sm:px-0 max-md:pt-20">
                        <p className="font-cormantThiner">10-10-2023</p>
                        <p className="font-semibold font-cormantThiner text-[25px]">
                          vishwa
                        </p>

                        <ul className="flex justify-center ">
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                          <li>
                            <AiFillStar className="text-[#daa844] text-[30px]" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="w-[70%] pr-20 max-lg:px-10 max-lg:w-full">
                    <div className="w-[100%]">
                      <p>
                        <BiSolidQuoteLeft className="text-[102px] text-[#c7993d]" />
                      </p>
                      <p className="text-[18px] max-md:text-[20px] text-black font-cormantThiner text-justify">
                        3. "
                        <span className="font-serif text-xl font-medium text-blue-800">
                          {" "}
                          GJ Interiors,
                        </span>{" "}
                        commitment to quality is outstanding. They transformed
                        my retail space into an inviting and visually stunning
                        environment. I was impressed by their ability to capture
                        the essence of my brand while ensuring a great shopping
                        experience for customers."
                      </p>
                    </div>
                  </li>
                </ul>
              </center>
            </Slider>
          </div>
        </div>
      </section>

      {/* ======================================== */}
      <section>
        <div className="relative w-full h-auto ">
          <img
            className="object-cover object-bottom h-[300px] w-full max-md:h-[500px]"
            src={BeforFooterImage}
            alt="loading"
          />
          <div className="absolute top-0 w-full h-[100%] bg-blue-900 opacity-70"></div>
          <div className="absolute top-0 h-[100%] w-full">
            <p className="pl-48 max-sm:text-2xl  max-sm:px-4 max-lg:pl-5 pt-10 font-semibold  text-white font-cormantThiner text-[35px]">
              Want to find out more about how we can help? <br /> Have a project
              you’d like us to look at and want an idea of cost?
            </p>
            <p className="pl-48 mt-5 text-white max-sm:px-4 max-lg:pl-5 font-cormantThiner">
              GIVE US A CALL OR DROP US AN EMAIL AND WE’LL GET BACK TO YOU AS
              SOON AS WE CAN.
            </p>
            <div className="pl-48 mt-5 text-white max-lg:pl-5 font-cormantThiner ">
              <ul className="flex text-[18px] max-md:flex-col ">
                <li className="flex pr-20 ">
                  <p className="pr-5 mt-1">
                    <BsFillTelephoneFill />
                  </p>
                  <span>948 641 2933</span>
                </li>
                <li className="flex ">
                  <p>
                    <BiLogoGmail className="mt-1 mr-5" />
                  </p>
                  <span>josewin100@gmail.com</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
