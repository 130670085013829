import  { useEffect } from "react";

const ScrrolOnrender = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null; // Since this component doesn't render anything visible, we return null
};

export default ScrrolOnrender;