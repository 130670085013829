import React from "react";
import { Link } from "react-router-dom";

import CommercialInterior from "../Common-components/service-components/commercal-interior";

import Showroom from "../assets/images/Services-images/Commercial-image-interior/Showroom.jpg";
import BeaytyParlour from "../assets/images/Services-images/Commercial-image-interior/Beauty-parlour.jpeg";
import Saloon from "../assets/images/Services-images/Commercial-image-interior/Saloon.jpg";
import ReadymadeShops from "../assets/images/Services-images/Commercial-image-interior/readymadeshop.jpg";
import Cardshowroom from "../assets/images/Services-images/Commercial-image-interior/Carshowroom.jpg";
import Bakery from "../assets/images/Services-images/Commercial-image-interior/Bakery.jpg";
import Reasturent from "../assets/images/Services-images/Commercial-image-interior/Reasturent.jpg";
import RetailShowroom from "../assets/images/Services-images/Commercial-image-interior/Retailshowroom.jpg";
import CorporateOffice from "../assets/images/Services-images/Commercial-image-interior/Corporate-office.jpg";
import School from "../assets/images/Services-images/Commercial-image-interior/School.jpg";
import Colleges from "../assets/images/Services-images/Commercial-image-interior/College.jpg";
import SuperMarket from "../assets/images/Services-images/Commercial-image-interior/Supermarket.jpg";

import GlazingCladding from "../assets/images/Services-images/corporate-interior-image/Glass-caddalling.jpg";
import CabinPartitions from "../assets/images/Services-images/corporate-interior-image/Cabins.jpg";
import OfficeFurniture from "../assets/images/Services-images/corporate-interior-image/Office-Furniture.jpg";
import OfficeFalseCeiling from "../assets/images/Services-images/corporate-interior-image/False-Celling.jpg";
import OfficeFlooring from "../assets/images/Services-images/corporate-interior-image/Flooring.jpg";
import OfficeWallDesigns from "../assets/images/Services-images/corporate-interior-image/Wall-designe.jpg";
import LightsArrangement from "../assets/images/Services-images/corporate-interior-image/Ligets-arrangements.jpg";
import ReceptionArea from "../assets/images/Services-images/corporate-interior-image/Reception-area.jpg";
import CafeteriaArea from "../assets/images/Services-images/corporate-interior-image/Cafeteria.jpg";

import One from "../assets/images/Services-images/Home-interior-images/One.jpg";
import BedRoom from "../assets/images/Services-images/Home-interior-images/BedRoom.jpg";
import Wardboard from "../assets/images/Services-images/Home-interior-images/wardboard.jpg";
import LivingRoom from "../assets/images/Services-images/Home-interior-images/Livingroom.jpg";
import Bathroom from "../assets/images/Services-images/Home-interior-images/BathRoom.jpg";
import PoojaRoom from "../assets/images/Services-images/Home-interior-images/pooja-room.jpg";
import KidsRoom from "../assets/images/Services-images/Home-interior-images/kids-room.webp";
import Cocakary from "../assets/images/Services-images/Home-interior-images/Cocary.jpg";
import Utility from "../assets/images/Services-images/Home-interior-images/utility.jpg";
import HomeInteriorscomponents from "../Common-components/service-components/home-interior";
import CorporateInterior from "../Common-components/service-components/corporate-interior";
import ScrrolOnrender from "../Common-components/scrol-on-render";
import { Helmet } from "react-helmet";
const Services = () => {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Love Your Home Again: GJ Interiors' Design Expertise.</title>
          <link rel="canonical" href="http://gjinterior.com/service" />
          <meta    name="description" content="Looking to revamp your space with the help of experts? Look no further than GJ Interiors! Our services are tailored to bring your vision to life. Contact us today to learn more about how GJ Interiors can transform your space." />
        </Helmet>
      </div>
      <ScrrolOnrender />
      <section>
        <div className="bg-servicebanner font-popins h-[300px] bg-fixed bg-no-repeat bg-bottom bg-cover relative  ">
          <div className=" w-full h-full bg-black opacity-[0.7] "></div>
          <div className="absolute top-0 z-10 flex flex-col items-start justify-center w-full h-full p-10 text-white ">
            <div>
              <nav aria-label="breadcrumb" className="w-full p-4 text-white">
                <ol className="flex h-8 space-x-2">
                  <li className="flex items-center">
                    <Link
                      rel="noopener noreferrer"
                      title="Back to homepage"
                      className="hover:underline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 pr-1 text-white"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                    </Link>
                  </li>
                  <li className="flex items-center space-x-1">
                    <span className="text-white">/</span>
                    <Link
                      rel="noopener noreferrer"
                      className="flex items-center px-1 capitalize hover:underline"
                    >
                      Welcom to Service Page
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="text-[60px] font-medium">Service Page</div>
          </div>
        </div>
      </section>

      {/* ========================== Best Residential Interior Designers in Chennai ============= */}
      <section>
        <div className="mt-10">
          <h2 className="text-[38px] max-md:px-5 max-md:text-[25px] text-center text-blue-400 font-medium font-popins  ">
            Commercial Interior Designers in kanyakumari
          </h2>

          <h2 className="text-[30px]  uppercase max-lg:px-20 max-sm:text-[30px] max-sm:px-6 max-lg:text-[45px] px-56 font-bold text-center text-gray-600   ">
            Our Commercial Interior Designing Services
          </h2>

          <center>
            <div className="w-36 h-[2px] mt-5 bg-red-600"></div>
          </center>
        </div>

        {/* ====================================================================== */}
        <section>
          <div className="px-16 mt-16 max-md:px-6">
            <section className="py-6">
              <div className="container flex flex-col justify-center p-4 mx-auto">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                  <CommercialInterior
                    img={Showroom}
                    heading="
                    Showrooms"
                  />

                  <CommercialInterior
                    img={BeaytyParlour}
                    heading="
                    Beauty Parlour"
                  />

                  <CommercialInterior
                    img={Saloon}
                    heading="
                    Saloon"
                  />

                  <CommercialInterior
                    img={ReadymadeShops}
                    heading="
                    Readymade Shops"
                  />

                  <CommercialInterior
                    img={Cardshowroom}
                    heading="
                    Car Showroom"
                  />

                  <CommercialInterior img={Bakery} heading="Bakery" />

                  <CommercialInterior
                    img={Reasturent}
                    heading="
                    Restaurant"
                  />

                  <CommercialInterior
                    img={RetailShowroom}
                    heading="
                    Retail Showroom"
                  />

                  <CommercialInterior
                    img={CorporateOffice}
                    heading="
                    Corporate Office"
                  />

                  <CommercialInterior
                    img={School}
                    heading="
                    Schools"
                  />

                  <CommercialInterior
                    img={Colleges}
                    heading="
                 Colleges"
                  />

                  <CommercialInterior
                    img={SuperMarket}
                    heading=" Supermarket"
                  />
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>
      {/*  */}
      <section>
        <div className="mt-10">
          <h2 className="text-[38px] max-md:px-5 max-md:text-[25px] text-center text-blue-400 font-medium font-popins  ">
            Our Home Interior Designers in kanyakumari
          </h2>

          <h2 className="text-[30px]  uppercase max-lg:px-20 max-sm:text-[30px] max-sm:px-6 max-lg:text-[45px] px-56 font-bold text-center text-gray-600   ">
            Our Home Interior Designing Services
          </h2>

          <center>
            <div className="w-36 h-[2px] mt-5 bg-red-600"></div>
          </center>
        </div>

        {/* ====================================================================== */}
        <section>
          <div className="px-16 mt-16 max-md:px-6">
            <section className="py-6">
              <div className="container flex flex-col justify-center p-4 mx-auto">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                  <HomeInteriorscomponents
                    img={One}
                    heading="Modular Kitchen"
                  />

                  <HomeInteriorscomponents img={BedRoom} heading="Bed Room" />

                  <HomeInteriorscomponents
                    img={Wardboard}
                    heading="wardrobes and loft"
                  />

                  <HomeInteriorscomponents
                    img={LivingRoom}
                    heading="Living Room"
                  />

                  <HomeInteriorscomponents img={Bathroom} heading="Bath Room" />

                  <HomeInteriorscomponents
                    img={PoojaRoom}
                    heading="Pooja Room "
                  />

                  <HomeInteriorscomponents img={KidsRoom} heading="Kids Room" />

                  <HomeInteriorscomponents
                    img={Cocakary}
                    heading="Crockery Unit"
                  />

                  <HomeInteriorscomponents
                    img={Utility}
                    heading="Utility Area Space"
                  />
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* ============================================== */}
      </section>

      {/* ========================== Best Residential Interior Designers in Chennai ============= */}
      <section>
        <div className="mt-10">
          <h2 className="text-[38px] max-md:px-5 max-md:text-[25px] text-center text-blue-400 font-medium font-popins  ">
            Corporate Interior Designers in kanyakumari
          </h2>

          <h2 className="text-[30px]  uppercase max-lg:px-20 max-sm:text-[30px] max-sm:px-6 max-lg:text-[45px] px-56 font-bold text-center text-gray-600   ">
            Corporate Interior Designing Services
          </h2>

          <center>
            <div className="w-36 h-[2px] mt-5 bg-red-600"></div>
          </center>
        </div>
      </section>

      {/* ====================================================================== */}
      <section>
        <div className="px-16 mt-16 max-md:px-6">
          <section className="py-6">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                <CorporateInterior
                  img={GlazingCladding}
                  heading="
                  Glazing Cladding"
                />

                <CorporateInterior
                  img={CabinPartitions}
                  heading="
                  Cabin and Partitions"
                />

                <CorporateInterior
                  img={OfficeFurniture}
                  heading="
                  Office Furniture"
                />

                <CorporateInterior
                  img={OfficeFalseCeiling}
                  heading="
                  Office False Ceiling"
                />

                <CorporateInterior
                  img={OfficeFlooring}
                  heading="
                  Office Flooring
                  "
                />

                <CorporateInterior
                  img={OfficeWallDesigns}
                  heading="
                  Office Wall Designs"
                />

                <CorporateInterior
                  img={LightsArrangement}
                  heading="
                  Lights Arrangement"
                />

                <CorporateInterior
                  img={ReceptionArea}
                  heading="

                  Reception Area"
                />

                <CorporateInterior
                  img={CafeteriaArea}
                  heading="
                  Cafeteria Area"
                />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Services;
