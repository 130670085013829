import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Logo from "../assets/images/section-bg/news.png";
import Star from "../assets/images/section-bg/star-shape-two.png";
import { AiFillInstagram } from "react-icons/ai";
//
import { FaFacebookF } from "react-icons/fa";
//
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";
//
import { FaBarsStaggered } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

const Header = () => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition >= 150) {
      setStickyHeader(true);
    } else {
      setStickyHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const onclickDrawer = () => {
    setDrawer(!drawer);
  };

  return (
    <>
      <nav className="">
        <div className="flex p-2 text-white bg-black">
          <Marquee className="font-serif font-light">
            <div className="mr-2">&#128522;</div>
          
            <div>
              Feeling Stuck? We unlock interior design solutions for any style.
            </div>
            <div className="ml-10"> &#128513;</div>
            <div>
              Love Modern Vibes? Explore our curated collection of contemporary
              furniture.
            </div>
            <img src={Star} className="h-5 mr-2" alt="" />
            <img src={Star} className="h-5 ml-2" alt="" />
            <div className="ml-2">
              What's Your Design Personality? Let us help you discover it.
            </div>
          </Marquee>
        </div>
      </nav>
      <div className={`${stickyHeader ? "is-sticky" : ""} ease-in-out bg-zinc-950  text-white`}>
        <div
          className={`" ${stickyHeader ? "shadow-md shadow-gray-500 text-black" : ""}  `}
        >
          <div className="container font-serif py-2 flex  items-center justify-between w-full  font-medium  max-lg:max-w-[95%] lg:max-w-[93%] duration-300 mx-auto  ">
            {" "}
            <div className="h-10">
              <Link to={"/"}>
                <img src={Logo} className="w-full h-full" alt="" />
              </Link>
            </div>
            <div>
              <ul className="flex gap-8 text-md max-lg:hidden">
                <li>
                  <Link to={"/"}>Home </Link>
                </li>
                <li>
                  <Link to={"/about"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/services"}>Services</Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="flex items-center gap-8 text-md font-popins max-lg:hidden">
                <li className="h-[25px] w-[2px] bg-black"></li>
                <li>
                  <AiFillInstagram />
                </li>
                <li>
                  <FaFacebookF />
                </li>
                <li>
                  <RiTwitterXLine />
                </li>
              </ul>
            </div>
            {/* ----- side-bar */}
            <div>
              <section>
                <div class="flex flex-wrap justify-center  text-gray-300 ">
                  <a
                    href="tel:9486412933"
                    class="header-button button--aylen px-5 py-3 bg-gray-800 hover:bg-gray-700 hover:text-white relative block focus:outline-none border-2 border-solid rounded-3xl text-sm text-center font-semibold uppercase tracking-widest overflow-hidden"
                  >
                    Call Us
                  </a>
                </div>
              </section>
            </div>
            <div className="ml-5 lg:hidden" onClick={onclickDrawer}>
              <FaBarsStaggered className="w-5 h-5 dark:text-gray-600" />
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className={`${
          drawer
            ? "translate-x-0 duration-1000 ease-in-out"
            : "-translate-x-[500px] duration-1000 ease-in-out"
        } fixed right-0 top-0 z-50 w-full bg-transparent font-popins lg:hidden`}
      >
        <aside className="py-2 px-5 min-h-[100vh] w-[50%]  bg-gray-800 text-white relative">
          <nav className="flex flex-col justify-center text-sm ">
            <div className="flex justify-end">
              {" "}
              <MdOutlineClose
                onClick={onclickDrawer}
                className="text-3xl font-bold "
              />{" "}
            </div>

            <div className="mb-14">
              <div className="flex w-auto h-10 mt-5 sm:w-52">
                <Link to={"/"}>
                  <img src={Logo} className="w-full h-full" alt="loading" />
                </Link>
              </div>
            </div>
            <div className="text-xl font-semibold ">
              <ul className="space-y-6">
                <li>
                  <Link to={"/"}> Home</Link>{" "}
                </li>
                <li>
                  <Link to={"/about"}>About Us</Link>{" "}
                </li>
                <li>
                  <Link to={"/services"}>Service</Link>{" "}
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>{" "}
                </li>
                <li>
                  {" "}
                  <ul className="flex space-x-5">
                    <li>
                      <AiFillInstagram />
                    </li>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <RiTwitterXLine />
                    </li>
                  </ul>{" "}
                </li>
              </ul>
            </div>
          </nav>
        </aside>
      </div>
    </>
  );
};

export default Header;
