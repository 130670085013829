import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <section>
        <div className="bg-cover bg-banner h-[75vh] bg-fixed bg-bottom relative overflow-hidden bg-no-repeat w-full ">
          <div className=" w-full h-full bg-gray-900 text-start opacity-[0.5]"></div>
          <div className="absolute top-0 z-30 flex flex-col items-center justify-center w-full h-full text-center md:px-16">
            <h1 className="text-[60px] text-gray-50 font-medium font-RubkiDoodle  max-md:text-[35px]">
              We Innovate unique Interiors .
            </h1>
            <p className="pt-8 font-serif text-xl font-semibold text-white max-md:text-sm">
              We're a fully dedicated corporate service agency <br />{" "}
              collaborating with brands all over the world.
            </p>

            <div className="flex justify-center w-full pt-9">
            <Link to={"contact-us"}> <button class="banner-btn">Get Started</button> </Link> 
            </div>
          </div>
          <div class="absolute -bottom-[80px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              widht="500"
              height="200"
              viewBox="0 180 2500 200"
              fill="#ffffff"
            >
              <path
                class="st1"
                d="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250"
              >
                <animate
                  attributeName="d"
                  dur="5s"
                  values="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 400 50 400 400 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
